import { useMemo } from "react"
import { t } from "@lingui/macro"
import { Stack, styled } from "@mui/material"

import { GRID_TREE_DATA_GROUPING_FIELD } from "@mui/x-data-grid-pro"

import { TruncatedText } from "src/components/TruncatedText"
import { IColumn } from "src/types/data-grid-pro"
import { IPostAndCommentsItem } from "src/views/community-posts-and-comments/type"
import { formatInteger } from "src/lib/number"
import { Time } from "src/components/Time"
import { AccessTypeHeader } from "src/components/AccessTypeHeader"
import { FlagRenderer } from "src/views/community-posts-and-comments/components/FlagRenderer"

const NoWrap = styled("span")({
    whiteSpace: "nowrap",
})

const useCommunityPostsColumns = (): IColumn<IPostAndCommentsItem>[] => {
    return useMemo(
        () => [
            {
                field: "flags",
                headerName: t`community-posts-view.flags-header`,
                type: "string",
                minWidth: 150,
                disableReorder: true,
                renderCell: (params) => {
                    if (params.row["itemType"] === "comment") {
                        return ""
                    }
                    return FlagRenderer(
                        params.value,
                        params.row["flagsInComments"],
                    )
                },
                filterable: false,
            },
            {
                field: GRID_TREE_DATA_GROUPING_FIELD,
                filterable: false,
                sortable: false,
            },
            {
                field: "text",
                headerName: t`community-posts-view.description-header`,
                renderCell: (params) => {
                    if (params.row["itemType"] === "comment") {
                        return (
                            <Stack direction="row">
                                {FlagRenderer(params.row["flags"])}
                                <Stack width={6} />
                                <NoWrap>
                                    <TruncatedText
                                        text={params.value ?? ""}
                                        max={20}
                                    />
                                </NoWrap>
                            </Stack>
                        )
                    }
                    return (
                        <NoWrap>
                            <TruncatedText text={params.value ?? ""} max={20} />
                        </NoWrap>
                    )
                },
                type: "string",
                minWidth: 200,
                flex: 2,
            },
            {
                field: "community_name",
                headerName: t`community-posts-view.community-breadcrumb`,
                renderCell: (params) => (
                    <NoWrap>
                        <TruncatedText text={params.value ?? ""} max={20} />
                    </NoWrap>
                ),
                type: "string",
                minWidth: 120,
            },
            {
                field: "community_id",
                headerName: t`community-posts-view.community-id-header`,
                renderCell: (params) => params.value,
                type: "number",
                minWidth: 120,
            },
            {
                field: "post_id",
                headerName: t`community-posts-view.post-id-header`,
                renderCell: (params) => params.value,
                type: "number",
                minWidth: 120,
            },
            {
                field: "object_id",
                headerName: t`community-posts-view.object-id-header`,
                renderCell: (params) => params.value,
                type: "number",
                sortable: false,
                minWidth: 120,
            },
            {
                field: "author_name",
                headerName: t`community-posts-view.author-header`,
                renderCell: (params) => (
                    <NoWrap>
                        <TruncatedText text={params.value ?? ""} max={20} />
                    </NoWrap>
                ),
                minWidth: 120,
                type: "string",
            },
            {
                field: "handled",
                headerName: t`community-posts-view.handled-header`,
                filterable: false,
                sortable: false,
                renderCell: (params) => params.value.toString(),
                minWidth: 120,
            },
            {
                field: "internal_apartment_id",
                headerName: t`community-posts-view.internal-apartment-id-header`,
                renderCell: (params) => params.value,
                type: "string",
                minWidth: 120,
            },
            {
                field: "apartment_no",
                headerName: t`community-posts-view.apartment-no-header`,
                renderCell: (params) => params.value,
                type: "string",
                minWidth: 120,
            },
            {
                field: "likes",
                headerName: t`community-posts-view.likes-header`,
                filterable: false,
                sortable: false,
                renderCell: (params) => formatInteger(params.value),
                minWidth: 120,
            },
            {
                field: "comments_count",
                headerName: t`community-posts-view.comments-header`,
                filterable: false,
                sortable: false,
                renderCell: (params) => formatInteger(params.value),
                minWidth: 120,
            },
            {
                field: "created_at",
                headerName: t`community-posts-view.created-header`,
                renderCell: (params) => <Time date={params.value} />,
                type: "date",
                minWidth: 120,
            },
            {
                field: "access_type",
                headerName: t`community-view.access-type`,
                filterable: false,
                sortable: false,
                renderCell: (params) => (
                    <AccessTypeHeader accessType={params.value} />
                ),
                minWidth: 120,
            },
            {
                field: "updated_at",
                headerName: t`community-comments-view.last-update-header`,
                renderCell: (params) => <Time date={params.value} />,
                type: "date",
                minWidth: 120,
            },
        ],
        [],
    )
}

export default useCommunityPostsColumns
