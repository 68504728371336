import {
    DataGridProProps,
    GridFilterModel,
    GridGroupNode,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    enUS,
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    svSE,
    useGridApiRef,
} from "@mui/x-data-grid-pro"
import { observer } from "mobx-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router"

import { DataGridContainer, StyledDataGridPro } from "./styled"

import { MixpanelProperties } from "src/analytics/constants/properties"
import { getLocationName } from "src/analytics/helpers/getLocationName"
import { trackEvent } from "src/analytics/helpers/mixpanel_tracking"
import {
    ActionDropdown,
    IActionDropdownItem,
} from "src/components/ActionDropdown"
import ContextMenu from "src/components/ContextMenu"
import { filterPanelSlotProp } from "src/components/Table/constants"
import { isNodeEnvironment } from "src/config/variables"
import {
    getFilterModelForRepository,
    getSortModelForRepository,
} from "src/lib/data-grid-pro"
import { Pagination } from "src/lib/pagination"
import { Locale } from "src/locales/locale"
import { GlobalStore } from "src/store"
import { useStore } from "src/store/lib/useStore"
import { Repository } from "src/types/channel"
import { IContextMenu } from "src/types/context-menu"
import {
    FilterModel,
    IAdvancedOperations,
    IColumn,
    ILocaleToDataGridLocaleMap,
    RowID,
    SortModel,
    TColumn,
    TPaginationModel,
    GridRowsProp,
} from "src/types/data-grid-pro"

interface IProps<TItem extends { id: string | number }> {
    paginator: Pagination<TItem, unknown>
    data: GridRowsProp
    hideToolbar?: boolean
    columns: IColumn<TItem>[]
    repository: Repository
    advancedOperations?: IAdvancedOperations
    onRowClickEvent?: (params: GridRowParams) => void
    rowActionsRenderer?: (item: TItem) => IActionDropdownItem[]
    loading?: boolean
    onSortChange?: (model: SortModel) => Promise<void>
    onFilterChange?: (model: FilterModel) => Promise<void>
    treeData?: boolean
    groupingColDef?: DataGridProProps["groupingColDef"]
    filteringInitialState?: GridFilterModel
}

const dataGridLanguageMap: ILocaleToDataGridLocaleMap = {
    [Locale.English]: enUS.components.MuiDataGrid.defaultProps.localeText,
    [Locale.Swedish]: svSE.components.MuiDataGrid.defaultProps.localeText,
}

export const DataGridProTable = observer(
    <TItem extends { id: string | number }>(props: IProps<TItem>) => {
        const [clickedRowID, setClickedRowID] = useState<RowID>()
        const [columns, setColumns] = useState<IColumn<TItem>[]>(props.columns)
        const [contextMenuPayload, setContextMenuPayload] =
            useState<IContextMenu | null>(null)
        const expansionState = useRef<{ [key: string]: boolean }>({})
        const globalStore = useStore(GlobalStore)

        const apiRef = useGridApiRef()
        const location = useLocation()

        const {
            paginator,
            data,
            advancedOperations,
            onRowClickEvent,
            repository,
            rowActionsRenderer,
            loading,
            onSortChange,
            onFilterChange,
            hideToolbar,
        } = props

        useEffect(() => {
            apiRef.current.subscribeEvent("rowExpansionChange", (node) => {
                expansionState.current[node.id] = node.childrenExpanded ?? false
            })
        }, [apiRef])

        useEffect(() => {
            if (
                props.filteringInitialState != null &&
                apiRef.current.setFilterModel !== undefined
            ) {
                apiRef.current.setFilterModel(props.filteringInitialState)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [apiRef.current.setFilterModel])

        const isGroupExpanded = useCallback(
            (node: GridGroupNode) => expansionState.current[node.id] ?? false,
            [expansionState],
        )

        useEffect(() => {
            if (rowActionsRenderer != null) {
                const rowRendererColumn: IColumn<TItem> = {
                    field: "Actions" as keyof TItem,
                    headerName: "",
                    filterable: false,
                    sortable: false,
                    disableColumnMenu: true,
                    resizable: false,
                    hideable: false,
                    minWidth: 40,
                    flex: 0.5,
                    renderCell: (params) => {
                        const tableItem = paginator.items.find(
                            (item) => item.id === params.id,
                        )
                        if (tableItem === undefined) {
                            return <></>
                        }
                        return (
                            <ActionDropdown
                                items={rowActionsRenderer(tableItem)}
                            />
                        )
                    },
                }

                setColumns([rowRendererColumn, ...props.columns])
            }
        }, [paginator.items, props.columns, rowActionsRenderer])

        const deselectRow = useCallback(() => {
            if (clickedRowID !== undefined) {
                apiRef.current.selectRow(clickedRowID, false)
            }
        }, [clickedRowID, apiRef])

        useEffect(() => {
            if (globalStore.modals.active.length === 0) {
                deselectRow()
            }
        }, [globalStore.modals.active.length, deselectRow])

        const columnsWithDefaultSizingAndCustomFilters = useMemo(
            () =>
                /*
                 * maybe there's a better way to handle type here using IColumn<TItem>
                 * Currently ts gives error for field key, expects string but we want it to be as keyof TItem
                 */
                (columns as TColumn[]).map((col) => {
                    // insert default minWidth and flex for all columns
                    col = {
                        ...col,
                        minWidth: col.minWidth ?? 75,
                        flex: col.flex ?? 1,
                    }

                    switch (col.type) {
                        case "string":
                            return {
                                ...col,
                                filterOperators:
                                    getGridStringOperators().filter(
                                        (operator) =>
                                            operator.value === "contains" ||
                                            operator.value === "equals",
                                    ),
                            }

                        case "date":
                            return {
                                ...col,
                                filterOperators: getGridDateOperators().filter(
                                    (operator) =>
                                        operator.value === "after" ||
                                        operator.value === "onOrAfter" ||
                                        operator.value === "before" ||
                                        operator.value === "onOrBefore",
                                ),
                            }

                        case "dateTime":
                            return {
                                ...col,
                                filterOperators: getGridDateOperators(
                                    true,
                                ).filter(
                                    (operator) =>
                                        operator.value === "after" ||
                                        operator.value === "onOrAfter" ||
                                        operator.value === "before" ||
                                        operator.value === "onOrBefore",
                                ),
                            }

                        case "number":
                            return {
                                ...col,
                                filterOperators:
                                    getGridNumericOperators().filter(
                                        (operator) =>
                                            operator.value === "=" ||
                                            operator.value === "isAnyOf",
                                    ),
                            }

                        case "singleSelect":
                            return {
                                ...col,
                                filterOperators:
                                    getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value === "is" ||
                                            operator.value === "isAnyOf",
                                    ),
                            }

                        default:
                            return col
                    }
                }),
            [columns],
        )

        const serverSidePagination = useCallback(
            (model: TPaginationModel) => {
                paginator
                    .loadPaginationModel(model)
                    .then(() => null)
                    .catch(() => null)
            },
            [paginator],
        )

        const clientSidePagination = useCallback(
            (model: TPaginationModel) => {
                paginator.setPageSizeOnly(model.pageSize)
                paginator.setPage(model.page)
            },
            [paginator],
        )

        const handlePaginationModelChange = (model: TPaginationModel) => {
            advancedOperations?.pagination === "server"
                ? serverSidePagination(model)
                : clientSidePagination(model)
        }

        const onClick = useCallback(
            (params: GridRowParams) => {
                setClickedRowID(params.id)
                onRowClickEvent != null && onRowClickEvent(params)
            },
            [onRowClickEvent],
        )

        const handleSortingChange = useCallback(
            async (model: SortModel) => {
                globalStore.session.setDataGridSortModel({
                    [repository]: model,
                })
                onSortChange !== undefined && (await onSortChange(model))
            },
            [globalStore.session, repository, onSortChange],
        )

        const trackFilterEvent = (model: FilterModel) => {
            const modelItemsWithValues = model.items.filter((modelItem) => {
                const hasValue =
                    modelItem.value !== null && modelItem.value !== undefined
                if (typeof modelItem.value === "string") {
                    return hasValue && modelItem.value !== ""
                }
                return hasValue
            })
            const modelWithValues = { ...model, items: modelItemsWithValues }

            modelItemsWithValues.length > 0 &&
                trackEvent(
                    "General | Filter",
                    getLocationName(location.pathname),
                    {
                        [MixpanelProperties.Filters]: modelWithValues,
                        [MixpanelProperties.UserID]:
                            globalStore.session.user?.adminId,
                    },
                )
        }

        const handleFiltersChange = useCallback(
            async (model: FilterModel) => {
                globalStore.session.setDataGridFilterModel({
                    [repository]: model,
                })
                trackFilterEvent(model)
                onFilterChange !== undefined && (await onFilterChange(model))
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [globalStore.session, repository, onFilterChange],
        )

        const handleContextMenu = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        ) => {
            event.preventDefault()
            const target = event.target as HTMLElement
            const value = target.title !== "" ? target.title : target.innerText

            setContextMenuPayload({
                value,
                position:
                    contextMenuPayload === null
                        ? {
                              mouseX: event.clientX + 2,
                              mouseY: event.clientY - 6,
                          }
                        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                          // Other native context menus might behave different.
                          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                          null,
            })
        }

        const handleClose = useCallback(() => {
            setContextMenuPayload(null)
        }, [])

        const CustomGridToolbar = useCallback(() => {
            return hideToolbar === true ? null : (
                <GridToolbarContainer sx={{ padding: "8px 4px 0px 8px" }}>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                </GridToolbarContainer>
            )
        }, [hideToolbar])

        const getLocale = useCallback(() => {
            const dataGridLocale =
                dataGridLanguageMap[
                    globalStore.session
                        .language as keyof ILocaleToDataGridLocaleMap
                ]
            return dataGridLocale
        }, [globalStore.session.language])

        const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
            row.hierarchy

        return (
            <DataGridContainer>
                <StyledDataGridPro
                    treeData={props.treeData}
                    getTreeDataPath={getTreeDataPath}
                    groupingColDef={props.groupingColDef}
                    loading={loading}
                    localeText={getLocale()}
                    apiRef={apiRef}
                    sx={{
                        overflowX: "scroll",
                    }}
                    rows={data}
                    autoHeight={true}
                    slots={{ toolbar: CustomGridToolbar }}
                    columns={columnsWithDefaultSizingAndCustomFilters}
                    onRowClick={onClick}
                    pagination
                    paginationMode={advancedOperations?.pagination ?? "client"}
                    pageSizeOptions={[10, 25, 50, 100]}
                    paginationModel={{
                        page: paginator.meta.page,
                        pageSize: paginator.meta.pageSize,
                    }}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={paginator.meta.count ?? 0}
                    slotProps={{
                        filterPanel: filterPanelSlotProp,
                        row: { onContextMenu: handleContextMenu },
                    }}
                    sortingMode={advancedOperations?.sorting ?? "client"}
                    sortModel={getSortModelForRepository(
                        repository,
                        globalStore.session.dataGridSortModel,
                    )}
                    onSortModelChange={handleSortingChange}
                    filterMode={
                        props.treeData !== undefined
                            ? undefined
                            : advancedOperations?.filtering ?? "client"
                    }
                    filterModel={getFilterModelForRepository(
                        repository,
                        globalStore.session.dataGridFilterModel,
                    )}
                    onFilterModelChange={handleFiltersChange}
                    disableVirtualization={isNodeEnvironment("test")}
                    isGroupExpandedByDefault={isGroupExpanded}
                />
                <ContextMenu
                    key={Math.random()}
                    value={contextMenuPayload?.value ?? null}
                    contextMenu={contextMenuPayload?.position ?? null}
                    onClose={handleClose}
                />
            </DataGridContainer>
        )
    },
)
